function Bonfire() {
    return (
        <div className="article-content">
            <h2 className='article-content'>How to make a bonfire</h2>
            <p className="article-content">
                You need three ingredients.
            </p>
            <p className="article-content">
                <ol className='article-content'>
                    <li className='article-content'>
                        Tinder: stuff that catches quickly and burns fast. (leaves, paper)
                    </li>
                    <li className='article-content'>
                        Kindling: stuff that catches a little more slowly and takes a little longer to burn. (twigs, branches)
                    </li>
                    <li className='article-content'>
                        Firewood: stuff that takes a long time to catch and burns slowly. (logs)
                    </li>
                </ol>
            </p>
            <p className="article-content">
                To setup a good bonfire, the initial structure you layout is pretty important. Doing the pre-work can help you avoid doing a bunch of shenanigans later on to get your fire going.
            </p>
            <p className="article-content">
                The principles you want to follow for you initial structure is to have interconnected logs while leaving space for the passage of O2. The tinder and kindling lay the groundwork for the logs to heat up and create a self-sustaining fire. Fires will die out either because they run out of oxygen or because they didn't get hot enough to stay lit. The tinder will give way to heating the kindling, and the kindling will give way to heating the firewood.
            </p>
            <p className="article-content">
                You light your tinder and kindling first with your lighter. You can use some lighter fluid to get the reaction going faster, but in theory you shouldn't need it if you follow these principles ^.
            </p>
            <p className="article-content">
                I've tried and tested these two good structures for bonfires. They're pretty easy to setup and get going. I like to use the pyramid when I have a lot of kindling and tinder, and the log cabin when I have a lot of firewood.
            </p>
            <h2 className='article-content'>The Pyramid</h2>
            <img src="./pyramid.png" alt="pyramid" className="article-image" />
            <p className='article-content'>
                Line up three logs in a pyramid structure, allowing them all to heat at the base and let the fire travel up. You fill the middle with a mix of your tinder and kindling to initialize the heat. Establish a really hot middle, thereby helping sustain the reaction.
            </p>
            <h2 className='article-content'>
                The Log Cabin
            </h2>
            <img src="./log_cabin.png" alt="pyramid" className="article-image" />
            <p className='article-content'>
                Every two horizontal logs are followed by two vertical logs on the x-y axes. Start with one stack, and keep adding layers as the fire burns on. Fill the middle with tinder and kindling. The structure is more stable than the pyramid, but it's harder to get the fire going because the logs are more tightly packed.
            </p>
            <h2 className='article-content'>
                Do it Yourself
            </h2>
            <p className='article-content'>
                I really like fire. We should have more bonfires. But remember to burn responsibly. Always completely douse the embers of your fire when you're done with the pit for the night, and never bonfire when fire danger is high.
            </p>
        </div>
    );
}

export default Bonfire;
