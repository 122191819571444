import './style/App.css';

function About() {
    return (
        <div className="about">
            <p>
                I'm Saba Imran. I'm an engineer/founder working on Khoj, an <a href="https://github.com/khoj-ai/khoj">open-source, personal AI</a> that prioritizes trust and transparency. Previously, I worked at companies like Microsoft and Two Sigma, building AI-powered products and data ingestion pipelines for high frequency trading, respectively. I also worked at Probably Genetic, where I built machine learning models to predict the likelihood of rare genetic diseases in patients.
            </p>
            <p>
                Originally from Chicago. Studied Computer Science at the University of Illinois at Urbana-Champaign. Now living in San Francisco.
            </p>
            <p>
                Curious about the human impact of AI, and how we can shape a better future.
            </p>
            <p>
                You can find me here:
            </p>
            <ol className="social-links">
                <li>
                    <a className="inline-ref" rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/sabaimran/">linkedin</a>
                </li>
                <li>
                    <a className="inline-ref" rel="noreferrer" target="_blank" href="https://twitter.com/sabaimran_go">twitter</a>
                </li>
            </ol>

        </div>
    );
}

export default About;
