import TopicsBox from "../TopicsBox";

import { useEffect } from "react";

function ArticleHeader(props) {
    const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC'  // Force UTC to avoid timezone shifts
    };

    const locale = "en-US";

    const { title, topics, date } = props;

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <header className="article-content">
            <h1 className="article-content" >
                {title}
            </h1>
            <h4 className="article-content">{date.toLocaleDateString(locale, dateOptions)}</h4>
            <TopicsBox topics={topics} />
        </header>
    );
}

export default ArticleHeader;