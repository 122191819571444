function ColdPlunge() {
    return (
        <div className="article-content">
            <p className="article-content">
                With fifty others screaming and bounding past me, I rush uncompromisingly into the green-blue salty mixture of the Pacific. Well, not quite the Pacific-Pacific. It's the Aquatic Cove, a small enclave in the little pathway of water between San Francisco and Sausalito that's connected by the Golden Gate Bridge.
            </p>
            <p className="article-content">
                Everyone shouts their intentions into the wind, manic and unraveled as cold, biting water makes its way past our skin. I belt out "CURIOSITY!" while my feet, legs, stomach make their encounter with the water. The frantic splashing of our bodies leaves the otherwise placid water churning and undulating.
            </p>
            <p className="article-content">
                The first impact is exhilarating. A sudden reminder of what it means to be alive, what it means to be free and to exist, a body and a self. That these cold water molecules intersecting with my mammalian skin are pricking. I exhale, steeling myself against the embrace of this newfound friend. I submerge my head, letting the shouts of my fellow plungers disappear while basking in the sensation. Swimming ahead, I glide in the high density saltwater. Weightless, salty, murky, freed. I'm no longer a founder, a wife, a daughter. I'm just a human, revitalized.
            </p>
            <p className="article-content">
                Funny enough, the water looked like it was going to be a lot colder than it was. After a few minutes of shock and tickling, my body is warming. A defiant numbness starts shielding me from the reality of the temperature. I dance in the water, letting go of the weight that's been holding me down. In this moment, I can do anything.
            </p>
            <p className="article-content">
                My intention for the year is to spend more time with play. Be more open, do more of what gives me joy, spend less time worrying, and cull habits that are holding me back. I can be whoever I want to be. I don't need to worry about whether I'll get there as long as I'm putting in the reps to move in that direction.
            </p>
            <p className="article-content">
                Keep your eyes wide open and jump into the water. It's warmer once you get in.
            </p>
        </div>
    );
}

export default ColdPlunge;
