function Plants() {
    return (
        <div className="article-content">
            <p className="article-content">
                I usually set up an army of plants anywhere I stay. Most likely, they're satisfying a biological urge to nurture living things. Traditionally, that would be a baby. But currently, it is plants.
            </p>

            <p className="article-content">
                I'm currently harboring a diffenbachia, a massive strelitzia, a couple of rubber trees and a ficus, an army of philodendrons, and a zamioculcas zamiifolia, amongst other little ones. I was forced to pause my territory capture by my husband, who wondered when he agreed to live in a jungle.
            </p>

            <p className="article-content">
                Here are some reasons why plants give me immense joy and some lessons I've learned from them.
            </p>

            <ol className='article-content'>
                <li className='article-content'>
                    They help me understand the movement of time. Humans spaces can feel decidedly bare, left on their own. A couple of walls are hardly going to change over a month's span. In contrast, I'm constantly aware of the passage of time as my plants sprout new leaves and discard old ones.
                </li>
                <li className='article-content'>
                    It reminds me that things come in balance, including watering and feeding. If you over-fertilize or over-water, you could very well kill your plant by giving it too much of a proverbial good thing.
                </li>
                <li className='article-content'>
                    They demonstrate that placement and environment are usually more important than proactive action. I have often put plants in places in my home that receive less light, or aren't humid enough, only to watch as they lose leaves and start dropping. Doesn't matter how much I water them on time or fertilize them. On the contrary, putting them in the right spot, with an appropriate amount of sunlight, might transform them into a bloom state. Good positioning has compounding benefits.
                </li>
                <li className='article-content'>
                    As an extension of #3, sometimes the environments you have access to just aren't good enough for your plants. It doesn't make sense to keep trying when it doesn't fit with what you have.
                </li>
                <li className='article-content'>
                    When things grow, they can grow explosively. Once I put a plant in an optimum position for light and humidity, I might find it growing relentlessly. In the right conditions, growth happens at all costs.
                </li>
                <li className='article-content'>
                    I find a lot of personal fulfillment in watching new leaves unfurl. I love seeing the tiny stems inch up and out towards the sun, incubating little forks inside. The feeling is unlike any other, when I watch them unwrap, so bright, green, sprightly.
                </li>
            </ol>
        </div>
    );
}

export default Plants;
