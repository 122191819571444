import TopicsBox from "./TopicsBox";

import {
    Link
} from "react-router-dom";

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timeZone: 'UTC'  // Force UTC to avoid timezone shifts
};

const locale = "en-US";

function PostTile(props) {
  return (
    <div className="post-tile">
        <Link to={props.path} className="post-tile">
            <h1 className="post-tile">{props.title}</h1>
            <h3 className="post-tile">{props.date.toLocaleDateString(locale, dateOptions)}</h3>
            <p className="post-tile">{props.preview}</p>
        </Link>
        <TopicsBox topics={props.topics} />
    </div>
  );
}

export default PostTile;
